import React, { useState, useEffect } from 'react'

import Title from '../elements/Title'
import Subtitle from '../elements/Subtitle'
import SendFormButton from '../elements/SendFormButton'

import * as emailjs from 'emailjs-com'

import {
  Grid,
  FormControl,
  Input,
  MenuItem,
  Select,
  TextField,
  Backdrop,
} from '@material-ui/core'

import { colors, s, alpha } from '../style/style'

const { text, form } = require('../content/content')

// const emailjsService = 'gmail'
// const emailjsId = 'user_VCbPwtilGA2rhs6vDQeRL'
const emailjsServiceId = 'service_8enloxi'
const emailjsPublicKey = '6GvWkhFP8iK9a0kb0'

const ContactSection = () => {
  const initialStateGlobal = {
    name: '',
    email: '',
    phone: '',
  }

  const initialStateMessage = {
    message: '',
  }

  const initialStateOrder = {
    service: '',
    option: '',
    date: '',
    place: '',
    description: '',
  }
  const [valuesGlobal, setValuesGlobal] = useState(initialStateGlobal)
  const [valuesMessage, setValuesMessage] = useState(initialStateMessage)
  const [valuesOrder, setValuesOrder] = useState(initialStateOrder)
  const [openSelect, setOpenSelect] = useState(null)
  const [mailing, setMailing] = useState(null)
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [wasFormSubmited, setWasFormSubmited] = useState(false)

  const validate = () => {
    let { name, email, phone } = valuesGlobal
    return name && (email || phone)
  }

  const onFormSubmit = (type, event) => {
    setMailing('sending')
    let formValues, templateName
    event && event.preventDefault()
    setWasFormSubmited(true)

    switch (type) {
      case 'message':
        formValues = valuesMessage
        templateName = 'zadronowani_message'
        break
      case 'order':
        formValues = valuesOrder
        templateName = 'zadronowani_order'
        break
      default:
        return null
    }

    let templateParams = {
      id: Math.random().toString(36).substring(7).toUpperCase(),
      ...valuesGlobal,
      ...formValues,
    }

    console.log(templateParams);

    if (validate())
      setTimeout(() => {
        emailjs
          // .send(emailjsService, templateName, templateParams, emailjsId)
          .send(emailjsServiceId, templateName, templateParams, emailjsPublicKey)
          .then(
            result => {
              setMailing(true)
              setIsBackdropOpen(true)
              // console.log(result.text)
              setValuesGlobal(initialStateGlobal)
              setValuesMessage(initialStateMessage)
              setValuesOrder(initialStateOrder)
              setWasFormSubmited(false)
            },
            error => {
              // console.log(error)
              setIsBackdropOpen(true)
              setMailing(false)
              setWasFormSubmited(false)
            }
          )
      }, 2000)
    else setMailing('error')
    setWasFormSubmited(false)
  }

  const onGlobalFormChange = event => {
    setValuesGlobal({
      ...valuesGlobal,
      [event.target.name]: event.target.value,
    })
    if (validate()) setMailing(null) // ??????
  }

  const onMessageFormChange = event => {
    setValuesMessage({
      ...valuesMessage,
      [event.target.name]: event.target.value,
    })
  }

  const onOrderFormChange = event => {
    setValuesOrder({ ...valuesOrder, [event.target.name]: event.target.value })
    if (event.target.name === 'service')
      setValuesOrder({
        ...valuesOrder,
        [event.target.name]: event.target.value,
        option: '',
      })
  }

  useEffect(() => {
    if (wasFormSubmited && mailing !== 'sending')
      if (!validate()) setMailing('error')
      else setMailing(null)
  }, [mailing, wasFormSubmited, validate, setMailing])

  return (
    <div>
      {/* <div css={{ position: 'absolute', bottom: 0, zIndex: 9999 }}>
        <span>
          {mailing === true
            ? 'TRUE'
            : mailing === null
            ? 'NULL'
            : mailing === false
            ? 'FALSE'
            : mailing === 'sending'
            ? 'SENDING'
            : mailing === 'error'
            ? 'ERROR'
            : 'dunno'}
        </span>
        <button onClick={() => setMailing('sending')}>sending</button>
        <button onClick={() => setMailing(true)}>true</button>
        <button onClick={() => setMailing(false)}>false</button>
        <button onClick={() => setMailing('error')}>error</button>
      </div> */}
      <Title tag="h2">{text.contact.title}</Title>
      <Subtitle>{text.contact.field}</Subtitle>
      <Grid
        id="formularz"
        container
        css={style.container}
        justifyContent="space-between"
        alignItems="center">
        <Grid
          item
          xs={12}
          lg={5}
          className="mailContainer"
          css={style.sectionContainer}>
          <Grid item xs={12}>
            <h5>{text.contact.form.title.messageForm}</h5>
          </Grid>
          <form
            autoComplete="off"
            onSubmit={event => onFormSubmit('message', event)}
            css={{ width: '100%' }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              css={style.innerContainer}>
              <Grid item xs={12} lg={12}>
                <Input
                  css={style.input}
                  type="text"
                  name="name"
                  value={valuesGlobal.name}
                  placeholder={text.contact.form.name}
                  onChange={onGlobalFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <Input
                  css={style.input}
                  type="email"
                  name="email"
                  value={valuesGlobal.email}
                  placeholder={text.contact.form.email}
                  onChange={onGlobalFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={1} css={style.orSmall}>
                {text.contact.form.or}
              </Grid>
              <Grid item xs={12} lg={5}>
                <Input
                  css={style.input}
                  type="tel"
                  name="phone"
                  value={valuesGlobal.phone}
                  placeholder={text.contact.form.phone}
                  onChange={onGlobalFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Input
                  css={[style.input, style.inputTextField]}
                  type="text"
                  name="message"
                  value={valuesMessage.message}
                  placeholder={text.contact.form.message}
                  onChange={onMessageFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={12} css={style.center}>
                <SendFormButton
                  onFormSubmit={onFormSubmit}
                  mailing={mailing}
                  type="message"
                />
              </Grid>
            </Grid>
          </form>
        </Grid>

        <p css={[style.orSmall, style.orBig]}>{text.contact.form.or}</p>
        <Grid
          item
          xs={12}
          lg={5}
          className="serviceContainer"
          css={style.sectionContainer}>
          <Grid item xs={12}>
            <h5>{text.contact.form.title.orderForm}</h5>
          </Grid>
          <form
            autoComplete="off"
            onSubmit={event => onFormSubmit('message', event)}
            css={{ width: '100%' }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              css={style.innerContainer}>
              <Grid item xs={12} lg={5}>
                <FormControl css={{ width: '100%' }}>
                  <Select
                    css={[
                      style.selectStyleTwo,
                      !valuesOrder.service && style.selectStyleDisabled,
                    ]}
                    open={openSelect === 'service' ? true : false}
                    onOpen={() => setOpenSelect('service')}
                    onClose={() => setOpenSelect(null)}
                    type="text"
                    name="service"
                    value={valuesOrder.service}
                    displayEmpty={true}
                    renderValue={() =>
                      (valuesOrder.service && form[valuesOrder.service].name) ||
                      text.contact.form.service
                    }
                    onChange={onOrderFormChange}>
                    {Object.entries(form).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl css={{ width: '100%' }}>
                  <Select
                    css={[
                      style.selectStyleTwo,
                      !valuesOrder.option && style.selectStyleDisabled,
                    ]}
                    open={
                      valuesOrder.service && openSelect === 'option'
                        ? true
                        : false
                    }
                    onOpen={() => setOpenSelect('option')}
                    onClose={() => setOpenSelect(null)}
                    type="text"
                    name="option"
                    value={valuesOrder.option}
                    displayEmpty={true}
                    renderValue={() =>
                      valuesOrder.option || text.contact.form.option
                    }
                    onChange={onOrderFormChange}>
                    {form[valuesOrder.service] &&
                      Object.values(form[valuesOrder.service].options).map(
                        (value, id) => (
                          <MenuItem key={id} value={value}>
                            {value}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Input
                  css={style.input}
                  type="text"
                  name="date"
                  value={valuesOrder.date}
                  placeholder={text.contact.form.date}
                  onChange={onOrderFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  css={style.input}
                  type="text"
                  name="place"
                  value={valuesOrder.place}
                  placeholder={text.contact.form.place}
                  onChange={onOrderFormChange}
                  // onChange={onGlobalFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  css={style.input}
                  type="text"
                  name="description"
                  value={valuesOrder.description}
                  placeholder={text.contact.form.description}
                  onChange={onOrderFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={12} xl={3}>
                <TextField
                  css={style.input}
                  type="text"
                  name="name"
                  value={valuesGlobal.name}
                  placeholder={text.contact.form.name}
                  onChange={onGlobalFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={5} xl={3}>
                <Input
                  css={style.input}
                  type="email"
                  name="email"
                  value={valuesGlobal.email}
                  placeholder={text.contact.form.email}
                  onChange={onGlobalFormChange}
                />
              </Grid>
              <Grid item xs={12} lg={1} css={style.orSmall}>
                {text.contact.form.or}
              </Grid>
              <Grid item xs={12} lg={5} xl={3}>
                <Input
                  css={style.input}
                  type="tel"
                  name="phone"
                  value={valuesGlobal.phone}
                  placeholder={text.contact.form.phone}
                  onChange={onGlobalFormChange}
                />
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} css={style.center}>
            <SendFormButton
              onFormSubmit={onFormSubmit}
              mailing={mailing}
              type="order"
            />
          </Grid>
        </Grid>
      </Grid>
      <Backdrop
        css={style.backdrop}
        open={isBackdropOpen}
        onClick={() => setIsBackdropOpen(false)}>
        <div>
          {mailing ? (
            <>
              <p>Dziękujemy!</p>
              <p>Otrzymaliśmy twoją wiadomość</p>
            </>
          ) : (
            <>
              <p>Błąd!</p>
              <p>Nie wysłano wiadomości</p>
            </>
          )}
        </div>
      </Backdrop>
    </div>
  )
}

const style = {
  input: {
    flexGrow: 1,
    width: '100%',
  },
  container: {
    [s.xs]: { padding: '0 1rem' },
    [s.sm]: { padding: '0 10vw' },
    h5: {
      textAlign: 'center',
      fontSize: 1.25 + 'rem',
      marginBottom: '2rem',
      color: '#ffffff'.concat(alpha[75]),
    },
    input: {
      transition: 'background-color 0.2s ease-out, color 0.2s ease-out',
      width: '100%',
      color: '#ffffff'.concat(alpha[60]),
      fontWeight: 700,
      fontFamily: 'Spartan',
      borderRadius: '2rem',
      padding: '0px 24px',
      border: 'none',
      backgroundColor: colors.main.light,
      [s.xs]: { fontSize: 0.75 + 'rem', height: 36, marginBottom: 1 + 'rem' },
      [s.sm]: { fontSize: 0.75 + 'rem', height: 36, marginBottom: 1 + 'rem' },
      [s.lg]: {
        fontSize: 0.875 + 'rem',
        height: 48,
        marginBottom: 1.5 + 'rem',
      },
      '&:hover, &:focus': {
        cursor: 'pointer',
        color: 'white',
        backgroundColor: '#ffffff'.concat(alpha[25]),
        '&::placeholder': { color: '#ffffff'.concat(alpha[75]) },
      },
    },
    '.MuiInput-underline, .MuiInput-underline:before, .MuiInput-underline:hover, .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after': {
      border: 'none',
    },
  },
  sectionContainer: {
    margin: '2rem 0',
    borderRadius: '2rem',
    backgroundColor: colors.main.dark,
    padding: '2rem',
    [s.xs]: { padding: '2rem 1rem' },
  },
  innerContainer: {
    // padding: '0 2rem 2rem',
  },
  center: {
    flexGrow: 1,
    justifySelf: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orSmall: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.main.lighter,
    textAlign: 'center',
    marginBottom: 1.5 + 'rem',
    fontWeight: 700,
    fontSize: 0.875 + 'rem',
  },
  orBig: {
    [s.xs]: { width: '100%' },
    color: '#ffffff'.concat(alpha[75]),
    fontSize: 1.25 + 'rem',
    marginBottom: 0 + 'rem',
  },
  inputTextField: {
    [s.sm]: { input: { height: 48 + 48 + 48 + 48 } },
    [s.xl]: { input: { height: 48 + 48 + 24 } },
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  selectStyle: {
    appearance: 'none',
    position: 'relative',
    '&:focus': {
      outline: 'none',
      border: 'none',
      boxShadown: 'none',
    },
    option: {
      fontWeight: 700,
      border: 'none',
      outline: 'none',
      appearance: 'none',
      paddingTop: '2rem',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
        outline: 'none',
        border: 'none',
      },
    },
  },
  selectStyleTwo: {
    transition: 'background-color 0.2s ease-out, color 0.2s ease-out',
    width: '100%',
    color: 'white',
    fontWeight: 700,
    fontFamily: 'Spartan',
    borderRadius: '2rem',
    padding: '0px 24px',
    border: 'none',
    backgroundColor: colors.main.light,
    '&:hover, &:focus': {
      cursor: 'pointer',
      color: 'white',
      backgroundColor: '#ffffff'.concat(alpha[25]),
      '&::placeholder': { color: '#ffffff'.concat(alpha[75]) },
    },
    svg: {
      color: 'white',
      marginRight: '1rem',
    },
    [s.xs]: { fontSize: 0.75 + 'rem', height: 36, marginBottom: 1 + 'rem' },
    [s.sm]: { fontSize: 0.75 + 'rem', height: 36, marginBottom: 1 + 'rem' },
    [s.lg]: { fontSize: 0.875 + 'rem', height: 48, marginBottom: 1.5 + 'rem' },
  },
  selectStyleDisabled: {
    color: '#ffffff'.concat(alpha[25]),
  },
  backdrop: {
    zIndex: 9999,
    position: 'fixed',
    top: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    backgroundColor: '#000000'.concat(alpha[90]),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    'p:nth-of-type(1)': {
      fontWeight: 300,
      [s.xs]: { fontSize: '2.75rem' },
      [s.sm]: { fontSize: '4rem' },
    },
    'p:nth-of-type(2)': {
      fontWeight: 700,
      [s.xs]: { fontSize: '1rem' },
      [s.sm]: { fontSize: '2rem' },
    },
  },
}

export default ContactSection
